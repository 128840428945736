<template>
    <div class="page-container">
        <div class="card">
            <div class="card-header">{{ page_title }}</div>
            <div class="card-body">
                <Form @submit="submitForm">

                    <div v-if="item.questions.length > 0">
                        <div v-for="(inQuestion, index) in item.questions" :key="index" class="mb-3">
                            <p class="collapse-qna-div mb-0">
                                <button class="btn btn-primary text-left w-100 h-100" @click="openDiv(index)" type="button" data-toggle="collapse" :data-target="'#questionDiv_'+(inQuestion.public_id) ? inQuestion.public_id : index" aria-expanded="false" :aria-controls="'questionDiv_'+(inQuestion.public_id) ? inQuestion.public_id : index">
                                    {{ inQuestion.question }}
                                </button>
                            </p>
                            <div :class="'collapse ' + (index == divIndex ? 'show' : 'hide') " :id="'questionDiv_'+(inQuestion.public_id) ? inQuestion.public_id : index">
                                <div class="card card-body border-bottom border-left-0 border-top-0 border-right-0">
                                    <input type="text" class="form-control" placeholder="Enter Question" v-model="inQuestion.question">
                                    <div class="mt-2">
                                        <div v-if="inQuestion.answers.length > 0" class="row">
                                            <div v-for="(inAnswer, i) in inQuestion.answers" :key="i" class="col-12">
                                                <div class="input-group mb-2">
                                                    <input type="text" class="form-control" v-model="inAnswer.answer" placeholder="Enter Option">
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-danger" v-if="inQuestion.answers.length > 1" @click="removeOption(index, i)">Remove Option</button>
                                                        <input class="form-check-input" type="radio" value=1 @change="qnaChecked(i, index)" v-model="inAnswer.is_correct_answer">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="ml-auto btn btn-primary mb-2" type="button" @click="addOption(index)">+ Add Option</button>
                                    <button type="button" class="btn btn-danger ml-auto" v-if="item.questions.length > 1" @click="removeQuestion(index)">Remove Question</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button type="button" class="btn btn-secondary mb-2" style="margin-left: 84%;" @click="addQuestion()">+ Add Question</button>

                    <!-- <div class="form-group row">
                        <label class="col-12 col-md-2">Text</label>
                        <div class="col-sm-10">
                            <Field as="textarea" name="text" :class="{'form-control':true,'is-invalid': errors.generation}" 
                                v-model="item.text" />
                            <ErrorMessage name="text"  class="invalid-feedback" />
                        </div>
                    </div> -->
                
                    
                    <div class="pt-3 border-top text-right">
                        <button class="btn btn-success" :disabled="submitProcessing" ref="item_form">
                            <span v-if="!submitProcessing">Update</span>
                            <span v-else>Please Wait...</span>
                        </button>
                        <!-- <button class="btn btn-success ml-2" type="button" @click="submitClose" :disabled="submitProcessing">
                            <span v-if="!submitProcessing">Save & Close</span>
                            <span v-else>Please Wait...</span>
                        </button>
                        <router-link class="btn btn-warning ml-2" :to="{name: 'BlastList'}" :disabled="submitProcessing">
                            <span v-if="!submitProcessing">Cancel</span>
                            <span v-else>Please Wait...</span>
                        </router-link> -->
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import API from "../../../api/http";
    import { Form } from 'vee-validate';
    import * as yup from 'yup';
    import { useToast } from "vue-toastification";

    export default {
        name: 'Quiz',
        components: {
            Form
        },
        computed:{
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        data(){
            const schema = yup.object().shape({
                first_name: yup.string().required().min(2),
                last_name: yup.string().required(),
                email: yup.string().required().email(),
                password: yup.string().min(6),
                generation: yup.string().required(),
                website: yup.string().url(),
            });

            return {
                page_title: 'Quiz',
                item_url: 'quiz',
                submitProcessing: false,
                submit_close: false,
                item: {
                    questions: []
                },
                divIndex: -1,
                validationSchema: schema,
                toast: useToast()
            }
        },
        created(){
            API.get(this.item_url + '/1')
            .then((response) => {
                if (response.quizzes) {
                    this.item = response.quizzes;
                } else {
                    this.item.questions.push({
                        question: null,
                        answers: []
                    })
                }
            });
        },
        methods: {
            openDiv(index){
                if (this.divIndex == index) {
                    this.divIndex = -1
                } else {
                    this.divIndex = index
                }
            },
            addQuestion () {
                this.item.questions.push({
                    question: null,
                    answers: []
                })
                this.divIndex = (this.item.questions.length - 1);
            },
            addOption (index) {
                this.item.questions[index].answers.push({
                    answer: null,
                    is_correct_answer: 0
                })
            },
            qnaChecked (i, index) {
                this.item.questions[index].answers.forEach((value, key) => {
                    this.item.questions[index].answers[key].is_correct_answer = 0
                })
                this.item.questions[index].answers[i].is_correct_answer = 1
            },
            removeQuestion (index) {
                if (this.item.questions[index].public_id) {
                    if (confirm("DO you want to delete question?")) {
                        API.post('delete-question/' + this.item.questions[index].public_id ,{ _method: 'DELETE',}).then(() => {
                            this.item.questions.splice(index, 1)
                        })
                    }
                } else {
                    this.item.questions.splice(index, 1)
                }
            },
            removeOption (index, i) {
                if (this.item.questions[index].answers[i].public_id) {
                    if (confirm("DO you want to delete Option?")) {
                        API.post('delete-answer/' + this.item.questions[index].answers[i].public_id ,{ _method: 'DELETE',}).then(() => {
                            this.item.questions[index].answers.splice(i, 1)
                        })
                    }
                } else {
                    this.item.questions[index].answers.splice(i, 1)
                }
            },
            submitClose(){
                this.submit_close = true;
                var form = this.$refs.item_form
                form.click()
            },
            submitForm(){
                this.submitProcessing = true;
                
                var postURL = this.item_url+ '/1';
                this.item._method = 'PATCH';

                API.post(postURL, this.item ).then((response) => {
                    this.toast.info(response.message);
                    if(this.submit_close){
                        this.$router.push({ name: 'QuizList' });
                    }
                    this.submitProcessing = false;
                }).catch((error) => {
                    this.submitProcessing = false;
                    console.log(error)
                });
            },
        }
    }
</script>