<template>
  <div class="page-container">
    <div class="header-wrap row">
      <div class="col-4"><h2 class="ml-2">{{ page_title }}</h2></div>
      <div class="col-6"></div>
      <div class="col-2 text-right">
            <router-link tag="button" class="btn btn-success btn-l" 
                :to="{name: 'SurveyAdd'}">
                    Add Survey
            </router-link>
          </div>
    </div>

    <div class="table-responsive table-responsive-data2">
      <table class="table table-data2">
        <thead>
            <tr>
                <th>Date</th>
                <!-- <th>User</th>
                <th>Blast</th>
                <th v-if="show_points">Points</th> -->
                <!-- <th class="create-at">Created at</th> -->
                <th class="text-right">Actions</th>
            </tr>
        </thead>
        <tbody>
            <template v-if="!data_loaded">
                <tr>
                    <td colspan="5">Please Wait...</td>
                </tr>
            </template>
            <template v-if="!items && data_loaded">
                <tr>
                    <td colspan="5">No {{ page_title }} Available</td>
                </tr>
            </template>
            <template v-else>
                <tr v-for="(item, index) in items" :key="item.publi_id" class="tr-shadow">
                    <td class="text-capitalize">{{ item.created }}</td>
                    <!-- <td>
                      <span class="block-email">{{ item.creator.member_name }}</span>
                    </td>
                    <td>{{ item.text }}</td>
                    <td v-if="show_points">{{ item.points }}</td> -->
                    <!-- <td>{{ item.created }}</td> -->
                    <td class="text-right">
                        <div class="btn-group">
                          <button class="btn btn-warning" type="button" v-if="item.flag"
                            @click="removeFlag(item.public_id,index)">
                            <!-- <i class="far fa-check-circle"></i> -->
                            <fa :icon="['far', 'fa-check-circle']" />
                          </button>
                          <router-link :to="{name: 'SurveyEdit', params: { public_id: item.public_id }}" 
                            class="btn btn-success" type="button">
                            Edit
                          </router-link>
                          <!-- <button class="btn btn-danger" type="button" @click="deleteItem(item.public_id,index)">
                            Delete
                          </button> -->
                        </div>
                    </td>
                </tr>
                <tr v-if="pagination.total_pages > 1">
                    <td colspan="5">
                        <nav>
                            <ul class="pagination">
                                <li :class="{'page-item': true, 'active': page === pagination.current_page}" v-for="page in pagination.total_pages" :key="page">
                                  <a href="JavaScript:void(0);" class="page-link" @click="init(page)"> {{ page }} </a>
                                </li>
                            </ul>
                        </nav>
                    </td>
                </tr>
            </template>
            
        </tbody>
      </table>
    </div> 

  </div>
</template>

<script>
import API from "../../../api/http";

export default {
  name: 'List',
  data(){
    return {
      page_title: 'Surveys',
      item_url: 'survey',
      data_loaded: false,
      show_points: false,
      search: null,
      pagination:{
        current_page: 1
      },
      items: {}
    }
  },
  created(){
    this.init(this.pagination.current_page);
  },
  methods: {
    init(current_page){

      var url = this.item_url + '?page=' + current_page;
      if(this.search){
        url += '&search=' + this.search
      }

      API.get(url).then((response) => {
        this.data_loaded = true;
        this.items = response.surveys.data;
        this.pagination = response.surveys.pagination;
      })
    },
    deleteItem(item_id,index){
      if(confirm('Are you sure?')){
        API.post(this.item_url + '/' + item_id ,{
          _method: 'DELETE',
        }).then((response) => {
          this.items.splice(index, 1)
          this.toast.info(response.message);
        })
      }
    }
  }
}
</script>