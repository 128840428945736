<template>
  <div class="page-container">
    <div class="header-wrap row">
      <div class="col-4"><h2 class="ml-2">{{ page_title }}</h2></div>
      <div class="col-6 text-right">
        <form @submit.prevent="init(1,true)" class="w-100 text-right">
          <div class="input-group">
            <input type="text" class="form-control" v-model="search" placeholder="Search">
            <div class="input-group-append">
              <button class="btn btn-success btn-l" type="button" @click="init(1,true)">Search</button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-2 text-right">
        <router-link tag="button" class="btn btn-success btn-l" 
            :to="{name: 'FlickAdd'}">
                Add Flick
        </router-link>
      </div>
    </div>

    <div class="table-responsive table-responsive-data2">
      <table class="table table-data2">
        <thead>
            <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Created By</th>
                <th>Status</th>
                <th class="create-at">Created at</th>
                <th class="text-right">Actions</th>
            </tr>
        </thead>
        <tbody>
            <template v-if="!data_loaded">
                <tr>
                    <td colspan="5">Please Wait...</td>
                </tr>
            </template>
            <template v-if="!items && data_loaded">
                <tr>
                    <td colspan="5">No {{ page_title }} Available</td>
                </tr>
            </template>
            <template v-else>
                <tr v-for="(item,index) in items" :key="item.publi_id" class="tr-shadow">
                    <td>
                      {{ item.name }}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>{{ item.submitted_by.member_name }}</td>
                    <td v-if="item.status == 'pending'">
                        <button class="btn btn-warning" @click="updateVerified(item.public_id,index)">
                            Pending
                        </button>
                    </td>
                    <td v-else-if="item.status == 'approved'">
                        <button class="btn btn-success" @click="updateVerified(item.public_id,index)">
                            Approved
                        </button>
                    </td>
                    <td>{{ item.created }}</td>
                    <td class="text-right">
                        <div class="btn-group">
                          <router-link :to="{name: 'FlickEdit', params: { public_id: item.public_id }}" class="btn btn-success" type="button">
                            Edit
                          </router-link>
                          <button class="btn btn-danger" type="button" @click="deleteItem(item.public_id,index)">
                            Delete
                          </button>
                        </div>
                    </td>
                </tr>
                <tr v-if="pagination.total_pages > 1">
                    <td colspan="5">
                        <nav>
                            <ul class="pagination">
                                <li :class="{'page-item': true, 'active': page === pagination.current_page}" v-for="page in pagination.total_pages" :key="page">
                                  <a href="JavaScript:void(0);" class="page-link" @click="init(page)"> {{ page }} </a>
                                </li>
                            </ul>
                        </nav>
                    </td>
                </tr>
            </template>
            
        </tbody>
      </table>
    </div> 

  </div>
</template>

<script>
import API from "../../../api/http";
import { useToast } from "vue-toastification";

export default {
  name: 'List',
  data(){
    return {
      page_title: 'Flicks',
      item_url: 'video',
      data_loaded: false,
      show_points: false,
      search: null,
      pagination:{
        current_page: 1
      },
      items: {},
      toast: useToast()
    }
  },
  created(){
    this.init(this.pagination.current_page);
  },
  methods: {
    init(current_page){
      var url = this.item_url + '?page=' + current_page;
      if(this.search){
        url += '&search=' + this.search
      }
      API.get(url).then((response) => {
        this.data_loaded = true;
        this.items = response.video_feed.data;
        this.pagination = response.video_feed.pagination;
      })
    },
    deleteItem(item_id,index){
      if(confirm('Are you sure?')){
        API.post(this.item_url + '/' + item_id ,{
          _method: 'DELETE',
        }).then((response) => {
          this.items.splice(index, 1)
          this.toast.info(response.message);
        })
      }
    },
    updateVerified(item_id,index){
      API.post('video-status/' + item_id).then((response) => {
        this.items[index].status = response.new_status
      })
    }
  }
}
</script>