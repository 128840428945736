<template>
  <div class="page-container">
    <div class="header-wrap row">
      <div class="col-6">
        <h2 class="ml-2">{{ page_title }}</h2>
      </div>
      <div class="col-6 text-right">
        <form @submit.prevent="init(1, true)" class="w-75 float-right">
          <div class="input-group">
            <input type="text" class="form-control" v-model="search" placeholder="Search" />
            <div class="input-group-append">
              <button class="btn btn-success btn-l" type="button" @click="init(1, true)">
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="table-responsive table-responsive-data2">
      <table class="table table-data2">
        <thead>
          <tr>
            <th>#</th>
            <th>User</th>
            <th>Content</th>
            <th>Tag</th>
            <!-- <th class="create-at">Created at</th> -->
            <th class="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="!data_loaded">
            <tr>
              <td colspan="5">Please Wait...</td>
            </tr>
          </template>
          <template v-if="!items && data_loaded">
            <tr>
              <td colspan="5">No {{ page_title }} Available</td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(item, index) in items" :key="item.public_id" class="tr-shadow">
              <td>{{ index + 1 }}</td>
              <td>{{ item.user ? item.user.member_name : null }}</td>
              <td>{{ item.content }}</td>
              <td>{{ item.tags ? item.tags.name : null }}</td>
              <!-- <td>{{ item.created }}</td> -->
              <td class="text-right">
                <div class="btn-group">
                  <router-link :to="{ name: 'ForumEdit', params: { public_id: item.public_id } }" class="btn btn-success"
                    type="button">
                    Edit
                  </router-link>
                  <button class="btn btn-danger" type="button" @click="deleteItem(item.public_id, index)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
            <tr v-if="pagination.total_pages > 1">
              <td colspan="5">
                <nav>
                  <ul class="pagination">
                    <li :class="{
                      'page-item': true,
                      active: page === pagination.current_page,
                    }" v-for="page in pagination.total_pages" :key="page">
                      <a href="JavaScript:void(0);" class="page-link" @click="init(page)">
                        {{ page }}
                      </a>
                    </li>
                  </ul>
                </nav>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import API from "../../../api/http";
import { useToast } from "vue-toastification";

export default {
  name: "List",
  data() {
    return {
      page_title: "Forum",
      item_url: "form-post",
      data_loaded: false,
      search: null,
      pagination: {
        current_page: 1,
      },
      items: {},
      toast: useToast(),
    };
  },
  created() {
    this.init(this.pagination.current_page);
  },
  methods: {
    init(current_page) {
      var url = this.item_url + "?page=" + current_page;
      if (this.search) {
        url += "&search=" + this.search;
      }

      API.get(url).then((response) => {
        this.data_loaded = true;
        this.items = response.posts.data;
        this.pagination = response.posts.pagination;
      });
    },
    deleteItem(item_id, index) {
      if (confirm("Are you sure?")) {
        API.post(this.item_url + "/" + item_id, {
        }).then((response) => {
          this.items.splice(index, 1);
          this.toast.info(response.message);
        });
      }
    },
  },
};
</script>
