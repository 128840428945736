<template>
    <div class="page-container">
        <div class="header-wrap row">
            <div class="col-4">
                <h2 class="ml-2">{{ page_title }}</h2>
            </div>
            <div class="col-8 text-right">
                <div class="row w-100">
                    <div class="input-group col-6">
                        <button :class="{ 'btn btn-info btn-sm': true }">Total Active Members : {{ total_member_count
                        }}</button>
                    </div>

                    <form @submit.prevent="init(1, true)" class="col-6 float-right">
                        <div class="input-group">
                            <input type="text" class="form-control" v-model="search" placeholder="Search">
                            <div class="input-group-append">
                                <button class="btn btn-success btn-l" type="button" @click="init(1, true)">Search</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="table-responsive table-responsive-data2">
            <table class="table table-data2">
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Refer By</th>
                        <th>Points</th>
                        <th>Verified</th>
                        <th v-if="show_points">Points</th>
                        <th class="create-at">Created at</th>
                        <th class="text-right">Actions</th>
                        <th class="text-right">DAO Status</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="!data_loaded">
                        <tr>
                            <td colspan="5">Please Wait...</td>
                        </tr>
                    </template>
                    <template v-if="!items && data_loaded">
                        <tr>
                            <td colspan="5">No {{ page_title }} Available</td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr v-for="(item, index) in items" :key="item.publi_id" class="tr-shadow">
                            <td>
                                <span class="block-email">{{ item.member_name }}</span>
                            </td>
                            <td>{{ item.referral_user_name }}</td>
                            <td>{{ item.leaderbord_pioint }}</td>
                            <td v-if="item.verified == 0">
                                <button class="btn btn-warning" @click="updateVerified(item.public_id, index)">
                                    <fa :icon="['fas', 'user-times']" />
                                </button>
                            </td>
                            <td v-else-if="item.verified == 1">
                                <button class="btn btn-success" @click="updateVerified(item.public_id, index)">
                                    <fa :icon="['fas', 'user-check']" />
                                </button>
                            </td>
                            <td v-if="show_points">{{ item.points }}</td>
                            <td>{{ item.created }}</td>
                            <td class="text-right">
                                <div class="btn-group">
                                    <router-link :to="{ name: 'UserEdit', params: { public_id: item.public_id } }"
                                        class="btn btn-success" type="button">
                                        Edit
                                    </router-link>
                                    <template v-if="item.role == 'user'">
                                        <button class="btn btn-danger" type="button"
                                            @click="deleteItem(item.public_id, index)">
                                            Delete
                                        </button>
                                    </template>


                                </div>
                            </td>
                            <td class="text-right" v-if="item.is_DAO_user">
                                <button class="btn btn-warning" @click="updateDAOStatus(item.public_id, 0, index)">
                                    Remove From DAO
                                </button>



                            </td>

                            <td class="text-right" v-if="!item.is_DAO_user">
                                <button class="btn btn-success" @click="updateDAOStatus(item.public_id, 1, index)"
                                    v-if="!item.is_DAO_user">
                                    Add As DAO
                                </button>
                            </td>
                        </tr>
                        <tr v-if="pagination.total_pages > 1">
                            <td colspan="5">
                                <nav>
                                    <ul class="pagination">
                                        <li :class="{ 'page-item': true, 'active': page === pagination.current_page }"
                                            v-for="page in pagination.total_pages" :key="page">
                                            <a href="JavaScript:void(0);" class="page-link" @click="init(page)"> {{ page }}
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </td>
                        </tr>
                    </template>

                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
import API from "../../../api/http";
import { useToast } from "vue-toastification";

export default {
    name: 'List',
    data() {
        return {
            page_title: 'Users',
            item_url: 'user',
            data_loaded: false,
            show_points: false,
            search: null,
            pagination: {
                current_page: 1
            },
            items: {},
            total_member_count: 0,
            toast: useToast()
        }
    },
    created() {
        this.init(this.pagination.current_page);
    },
    methods: {
        init(current_page) {
            var url = this.item_url + '?page=' + current_page;
            if (this.search) {
                url += '&search=' + this.search
            }
            API.get(url).then((response) => {
                this.data_loaded = true;
                this.items = response.user.data;
                this.pagination = response.user.pagination;
                this.total_member_count = response.total_active_members;
            })
        },
        deleteItem(item_id, index) {
            if (confirm('Are you sure?')) {
                API.post(this.item_url + '/' + item_id, {
                    _method: 'DELETE',
                }).then((response) => {
                    this.items.splice(index, 1)
                    this.toast.info(response.message);
                })
            }
        },
        updateVerified(item_id, index) {
            API.post('user-verified/' + item_id).then((response) => {
                this.items[index].verified = response.new_status
            })
        },
        updateDAOStatus(item_id, status, index) {
            API.post('update-user-dao-status' + '?user_public_id=' + item_id + '&dao_status=' + status).then((response) => {
                this.items[index].is_DAO_user = status;
                this.toast.info(response.message);
            })
        }
    }
}
</script>