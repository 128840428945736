<template>
  <div class="page-container">
    <div class="card" v-if="item">
      <div class="card-header">Edit {{ page_title }}</div>
      <div class="card-body">
        <Form @submit="submitForm" :validation-schema="validationSchema" v-slot="{ errors }">
          <div class="row border-bottom mb-3">
            <div class="form-group row col-6" name="Type">
              <div class="mx-2 w-100">
                <label>User</label>
                <input type="text" class="form-control text-capitalize" :value="item.user.member_name" readonly />
              </div>
            </div>
            <div class="form-group row col-6" name="Points">
              <div class="mx-2 w-100">
                <label>Tag</label>
                <input type="text" class="form-control" :value="item.tags.name" readonly />
              </div>
            </div>
          </div>

          <div class="form-group row col-12">
            <div class="w-100">
              <label>Content</label>
              <div>
                <Field as="textarea" name="content" rows="3"
                  :class="{ 'form-control': true, 'is-invalid': errors.content }" v-model="item.content" />
                <ErrorMessage name="content" class="invalid-feedback" />
              </div>
            </div>
          </div>

          <div class="pt-3 mt-3 border-top text-right">
            <button class="btn btn-success" :disabled="submitProcessing" ref="item_form">
              <span v-if="!submitProcessing">Save</span>
              <span v-else>Please Wait...</span>
            </button>
            <button class="btn btn-success ml-2" type="button" @click="submitClose" :disabled="submitProcessing">
              <span v-if="!submitProcessing">Save & Close</span>
              <span v-else>Please Wait...</span>
            </button>
            <router-link class="btn btn-warning ml-2" :to="{ name: 'ForumPosts' }" :disabled="submitProcessing">
              <span v-if="!submitProcessing">Cancel</span>
              <span v-else>Please Wait...</span>
            </router-link>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../api/http";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { useToast } from "vue-toastification";

export default {
  name: "Post",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  data() {
    const schema = yup.object().shape({
      content: yup.string().required().min(2),
    });

    return {
      page_title: "Post",
      item_url: "form-post",
      submitProcessing: false,
      submit_close: false,
      item: null,
      validationSchema: schema,
      toast: useToast(),
    };
  },
  created() {
    API.get(this.item_url + "/" + this.$route.params.public_id).then((response) => {
      this.item = response.post;
    });
  },
  methods: {
    submitClose() {
      this.submit_close = true;
      var form = this.$refs.item_form;
      form.click();
    },
    submitForm() {
      this.submitProcessing = true;
      var url = this.item_url;
      if (this.$route.name == "ForumEdit") {
        url = "update-form-post/" + this.$route.params.public_id;
      }

      API.post(url, this.item).then((response) => {
        this.toast.info(response.message);
        if (this.submit_close) {
          this.$router.push({ name: "ForumPosts" });
        }
        this.submitProcessing = false;
      }).catch((error) => {
        this.submitProcessing = false;
        console.log(error);
      });
    },
  },
};
</script>
