<template>
    <div class="page-container">
        <div class="card" >
            <div class="row card-header m-0 p-0 text-center">
                <div @click="tab = 'hastag'" :class="(tab == 'hastag') ? 'active-tab pointer p-2 col-2' : 'pointer p-2 col-2'">{{ page_title }}</div>
                <div @click="tab = 'badword'" :class="(tab == 'badword') ? 'active-tab border-left pointer p-2 col-2' : 'border-left pointer p-2 col-2'">Badwords</div>
            </div>
            <div class="card-body">
                <Form @submit="submitForm" v-if="tab == 'hastag'">
                  <div class="row border-bottom mb-3">
                      <div class="form-group row col-6" name="Type">
                          <div class="mx-2 w-100">
                              <input type="text" class="form-control text-capitalize" v-model="hastag" />
                          </div>
                      </div>
                  </div>
                  <div class="pt-3 text-right">
                    <button class="btn btn-success" :disabled="submitProcessing" ref="item_form">
                        <span v-if="!submitProcessing">Update</span>
                        <span v-else>Please Wait...</span>
                    </button>
                  </div>
                </Form>
                <Form @submit="submitBadwordForm" v-if="tab == 'badword'">
                  <div class="row border-bottom mb-3">
                      <div class="form-group row col-6" name="Type">
                          <div class="mx-2 w-100">
                                <label>BadWords:</label>
                                <div>
                                    <div class='tag-input mb-4'>
                                        <div v-for='(badword, index) in badwords' :key='index' class='tag-input__tag'>
                                        <span @click='removeTag(index)'>x</span>
                                        {{ badword.value }}
                                        </div>
                                        <input type='text' placeholder="Enter a BadWord" class='tag-input__text' v-on:keydown.enter.prevent="addTag" />
                                    </div>
                                </div>
                            </div>
                      </div>
                  </div>
                  <div class="pt-3 text-right">
                    <button class="btn btn-success" :disabled="submitProcessing" ref="item_form">
                        <span v-if="!submitProcessing">Update</span>
                        <span v-else>Please Wait...</span>
                    </button>
                  </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import API from "../../../api/http";
    import { Form } from 'vee-validate';
    import { useToast } from "vue-toastification";

    export default {
        name: 'Hastag',
        components: {
            Form
        },
        data(){

            return {
                page_title: 'Hastag',
                item_url: 'hastag-show',
                submitProcessing: false,
                submit_close: false,
                hastag: null,
                tab: 'hastag',
                badwords: [],
                toast: useToast()
            }
        },
        created(){
            API.get(this.item_url)
            .then((response) => {
                this.hastag = response.hastag.hastag;
            });
            API.get('badword')
            .then((response) => {
                this.badwords = response.badwords;
            });
        },
        methods: {
            addTag (event) {
                event.preventDefault()
                var val = event.target.value.trim()
                if (val.length > 0) {
                    this.badwords.push({value: val})
                    event.target.value = ''
                }
            },
            removeTag (index) {
                this.badwords.splice(index, 1)
            },
            submitClose(){
                this.submit_close = true;
                var form = this.$refs.item_form
                form.click()
            },
            submitForm(){
                this.submitProcessing = true;

                API.post('hastag-update', { hastag: this.hastag } ).then((response) => {
                    this.toast.info(response.message);
                    if(this.submit_close){
                        this.$router.push({ name: 'Leaderboard' });
                    }
                    this.submitProcessing = false;
                }).catch((error) => {
                    this.submitProcessing = false;
                    console.log(error)
                });
            },
            submitBadwordForm(){
                this.submitProcessing = true;

                API.post('badword/1', { badwords: this.badwords, _method: 'PATCH' } ).then((response) => {
                    this.toast.info(response.message);
                    if(this.submit_close){
                        this.$router.push({ name: 'Leaderboard' });
                    }
                    this.submitProcessing = false;
                }).catch((error) => {
                    this.submitProcessing = false;
                    console.log(error)
                });
            },
        }
    }
</script>