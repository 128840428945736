<template>
    <div class="page-container">
        <div class="card" v-if="item">
            <div class="card-header">Edit {{ page_title }}</div>
            <div class="card-body">
                <Form @submit="submitForm" :validation-schema="validationSchema" v-slot="{ errors }">

                    <div class="row border-bottom mb-3">
                        <div class="form-group row col-6" name="Type">
                            <div class="mx-2 w-100">
                                <label>Type</label>
                                <input type="text" class="form-control text-capitalize" :value="item.type" readonly />
                            </div>
                        </div>
                        <div class="form-group row col-6" name="Points">
                            <div class="mx-2 w-100">
                                <label>Points</label>
                                <input type="text" class="form-control" :value="item.points " readonly />
                            </div>
                        </div>

                        <div class="form-group row col-6" name="Saved Blast">
                            <div class="mx-2 w-100">
                                <label>Saved Blast</label>
                                <div>
                                    <input type="text" class="form-control" :value="item.saved_blast == 0 ? 'No' : 'Yes'" readonly />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="form-group row" name="Media">
                        <label class="col-12 col-md-2">Media</label>
                        <div class="col-sm-10">
                            <Field as="select" name="media_type" :class="{'form-control':true,'is-invalid': errors.media_type}" 
                            v-model="item.media_type" @change="changeMaxUpload">
                                <option value="text">Text</option>
                                <option value="image">Image</option>
                                <option value="gif">GIF</option>
                                <option value="audio">Audio</option>
                                <option value="video">Video</option>
                            </Field>
                            <ErrorMessage name="media_type" class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-12 col-md-2">Text</label>
                        <div class="col-sm-10">
                            <Field as="textarea" name="text" :class="{'form-control':true,'is-invalid': errors.text}" 
                                v-model="item.text" />
                            <ErrorMessage name="text"  class="invalid-feedback" />
                        </div>
                    </div>

                    <div class="form-group row" v-if="item.media_type != 'text'">
                        <label class="col-12 col-md-2">{{ item.media_type }}</label>
                        <div class="col-sm-10">
                            <input type="file" ref="file" id="file" multiple="multiple" @change="uploadFile">
                        </div>
                    </div>

                    <div class="form-group row" v-if="media && item.media_type != 'text'">
                        <div :class="item.media_type == 'image' ? 'col-3' : 'col-12' " v-for="(inMedia,index) in media" :key="index">
                            <div class="embed-responsive embed-responsive-16by9 w-100" v-if="item.media_type != 'image'">
                                <iframe class="embed-responsive-item" :src="inMedia" :autoplay="false" allowfullscreen></iframe>
                            </div>
                            <div v-else>
                                <img class="w-100" :src="inMedia">
                            </div>
                        </div>
                    </div>

                    <template v-if="comments && comments.length > 0">
                        <h3 class="mt-5">Comments</h3>
                        <div v-for="(comment) in comments" :key="comment.public_id">
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <span class="h5 pr-3">{{comment.creator.member_name}}</span>
                                    <span class="text-secondary">{{comment.created_at}}</span>
                                </div>
                                <div class="col-12">
                                    <span class="h6 pr-3">{{comment.comment}}</span>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="pt-3 mt-3 border-top text-right">
                        <button class="btn btn-success" :disabled="submitProcessing" ref="item_form">
                            <span v-if="!submitProcessing">Save</span>
                            <span v-else>Please Wait...</span>
                        </button>
                        <button class="btn btn-success ml-2" type="button" @click="submitClose" :disabled="submitProcessing">
                            <span v-if="!submitProcessing">Save & Close</span>
                            <span v-else>Please Wait...</span>
                        </button>
                        <router-link class="btn btn-warning ml-2" :to="{name: 'BlastList'}" :disabled="submitProcessing">
                            <span v-if="!submitProcessing">Cancel</span>
                            <span v-else>Please Wait...</span>
                        </router-link>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import API from "../../../api/http";
    import { Form, Field, ErrorMessage  } from 'vee-validate';
    import * as yup from 'yup';
    import { useToast } from "vue-toastification";
    import { serialize } from 'object-to-formdata'

    export default {
        name: 'Blast',
        components: {
            Form, Field, ErrorMessage,
        },
        computed:{
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        data(){
            const schema = yup.object().shape({
                media_type: yup.string().required(),
                text: yup.string().when("media_type", {
                    is: 'text',
                    then: yup.string().required("Text is required.")
                }),
            });

            return {
                page_title: 'Blast',
                item_url: 'blast',
                submitProcessing: false,
                submit_close: false,
                item: null,
                media: null,
                maxUpload: null,
                comments: [],
                validationSchema: schema,
                toast: useToast()
            }
        },
        created(){
            API.get(this.item_url + '/' + this.$route.params.public_id)
            .then((response) => {
                this.item = response.blast;
                this.media = response.blast.media_url;
                this.comments = response.blast.comments;
                this.maxUpload = this.item.media_type == 'image' ? 4 : 1
            });
        },
        methods: {
            changeMaxUpload(){
                this.maxUpload = this.item.media_type == 'image' ? 4 : 1
                this.media = [];
            },
            uploadFile(){
                if (this.$refs.file.files.length > this.maxUpload) {
                    this.toast.error("You are only allowed to upload a maximum of " + this.maxUpload + " files");
                } else {
                    this.item.media_url = [];
                    for( var i = 0; i < this.$refs.file.files.length; i++ ){
                        let file = this.$refs.file.files[i];
                        this.item.media_url.push(file);
                    }
                }
            },
            submitClose(){
                this.submit_close = true;
                var form = this.$refs.item_form
                form.click()
            },
            submitForm(){
                var formData
                const options = {
                    indices: true
                }
                this.submitProcessing = true;
                var postURL = this.item_url;

                if(this.$route.name == 'BlastEdit'){
                    postURL = this.item_url + '/' + this.$route.params.public_id
                    this.item._method = 'PATCH';
                    // if(Array.isArray(this.item.media_url)){
                    //     this.item.media_url = this.item.media_url[0]
                    // }
                }

                formData = serialize(this.item, options)
                API.post(postURL, formData, { 'Content-Type': 'multipart/form-data' } ).then((response) => {
                    this.toast.info(response.message);
                    if(this.submit_close){
                        this.$router.push({ name: 'BlastList' });
                    }
                    this.submitProcessing = false;
                }).catch((error) => {
                    this.submitProcessing = false;
                    console.log(error)
                });
            },
        }
    }
</script>