<template>
    <div class="page-container">
        <div class="card" v-if="item">
            <div class="card-header">Edit {{ page_title }}</div>
            <div class="card-body">
                <Form @submit="submitForm">

                    <div class="row border-bottom mb-3 pb-4">

                        <div class="form-group row col-12">
                            <div class="mx-2 w-100">
                                <label>Name:</label>
                                <div>
                                    <input class="form-control" v-model="item.name">
                                </div>
                            </div>
                        </div>

                        <div class="form-group row col-12">
                            <div class="mx-2 w-100">
                                <label>Description:</label>
                                <div>
                                    <textarea maxlength="50" class="form-control" v-model="item.description"></textarea>
                                </div>
                            </div>
                        </div>

                        
                        <div class="form-group row col-12">
                            <div class="mx-2 w-100">
                                <label>Keywords:</label>
                                <div>
                                    <div class='tag-input'>
                                        <div v-for='(keyword, index) in item.keywords' :key='index' class='tag-input__tag'>
                                        <span @click='removeTag(index)'>x</span>
                                        {{ keyword }}
                                        </div>
                                        <input type='text' v-if="item.keywords.length < 5" placeholder="Enter a Tag" class='tag-input__text' v-on:keydown.enter.prevent="addTag" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row col-6">
                            <div class="col-12">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" @click="video_type = 'youtube'" 
                                        :class="{'btn':true,'btn-secondary': video_type != 'youtube','btn-info': video_type == 'youtube'}">Youtube URL</button>
                                    <button type="button" @click="video_type = 'upload'" 
                                        :class="{'btn':true,'btn-secondary': video_type != 'upload','btn-info': video_type == 'upload'}">Upload video</button>
                                </div>
                            </div>
                            <div v-if="video_type == 'youtube'" class="col-12 my-2">
                                <label>Youtube URL:</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" v-model="item.video_url" />
                                    <div class="input-group-append">
                                        <a :href="video" target="_blank" class="input-group-text" >Preview</a>
                                    </div>
                                </div>
                            </div>
                            <div v-if="video_type == 'upload'" class="col-12 my-2">
                                <div class="input-group mb-3">
                                    <input type="text" readonly class="form-control" v-model="item.video_url" />
                                    <div class="input-group-append">
                                        <a :href="video" target="_blank" class="input-group-text">Preview</a>
                                    </div>
                                </div>
                                <label>Upload Video:
                                    <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
                                </label>
                            </div>
                        </div>

                        <div class="form-group row col-6">
                            <div class="col-12">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" @click="thumb_type = 'url'" 
                                        :class="{'btn':true,'btn-secondary': thumb_type != 'url','btn-info': thumb_type == 'url'}">Thumbnail URL</button>
                                    <button type="button" @click="thumb_type = 'thumb-upload'" 
                                        :class="{'btn':true,'btn-secondary': thumb_type != 'thumb-upload','btn-info': thumb_type == 'thumb-upload'}">Upload Thumbnail</button>
                                </div>
                            </div>
                            <div v-if="thumb_type == 'url'" class="col-12 my-2">
                                <label>Thumbnail URL:</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" v-model="item.thumbnail" />
                                    <div class="input-group-append">
                                        <a :href="thumb_image" target="_blank" class="input-group-text" >Preview</a>
                                    </div>
                                </div>
                            </div>
                            <div v-if="thumb_type == 'thumb-upload'" class="col-12 my-2">
                                <div class="input-group mb-3">
                                    <input type="text" readonly class="form-control" v-model="item.thumbnail" />
                                    <div class="input-group-append">
                                        <a :href="thumb_image" target="_blank" class="input-group-text" >Preview</a>
                                    </div>
                                </div>
                                <label>Upload Thumbnail:
                                    <input type="file" id="file" ref="file" v-on:change="handleThumbUpload()"/>
                                </label>
                            </div>
                        </div>

                    </div>
                    
                    <div class="pt-3 text-right">
                        <button class="btn btn-success" :disabled="submitProcessing" ref="item_form">
                            <span v-if="!submitProcessing">Save</span>
                            <span v-else>Please Wait...</span>
                        </button>
                        <button class="btn btn-success ml-2" type="button" @click="submitClose" :disabled="submitProcessing">
                            <span v-if="!submitProcessing">Save & Close</span>
                            <span v-else>Please Wait...</span>
                        </button>
                        <router-link class="btn btn-warning ml-2" :to="{name: 'FlickList'}" :disabled="submitProcessing">
                            <span v-if="!submitProcessing">Cancel</span>
                            <span v-else>Please Wait...</span>
                        </router-link>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import API from "../../../api/http";
    import { useToast } from "vue-toastification";
    import { Form } from 'vee-validate';
    import { serialize } from 'object-to-formdata'

    export default {
        name: 'Flick',
        components: {
            Form
        },
        computed:{
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        data(){
            return {
                page_title: 'Flick',
                item_url: 'video',
                submitProcessing: false,
                submit_close: false,
                item: {
                    video_url: null,
                    thumbnail: null,
                    description: null,
                    name: null,
                    keywords: []
                },
                video_type: 'youtube',
                videoShowHide: 'hide',
                thumbShowHide: 'hide',
                thumb_type: 'url',
                video: '',
                thumb_image: '',
                toast: useToast(),
                tagsFocus: false
            }
        },
        created(){
            if (this.$route.params.public_id) {
                API.get(this.item_url + '/' + this.$route.params.public_id)
                .then((response) => {
                    this.item = response.video_feed;
                    if (this.item.video_url != "") {
                        if (this.item.video_url.includes("youtube")) {
                            this.video_type = 'youtube'
                        } else {
                            this.video_type = 'upload'
                        }
                        this.video = this.item.video_url
                    }
                    if (this.item.thumbnail != "") {
                        if (this.item.thumbnail.includes("youtube")) {
                            this.thumb_type = 'url'
                        } else {
                            this.thumb_type = 'thumb-upload'
                        }
                        this.thumb_image = this.item.thumbnail
                    }
                    if (this.item.keywords.length < 1) {
                        this.item.keywords = [];
                    }
                });
            }
        },
        methods: {
            videoPreview(){
                if (this.videoShowHide == 'hide') {
                    this.videoShowHide = 'show'
                } else {
                    this.videoShowHide = 'hide'
                }
            },
            thumbPreview(){
                if (this.thumbShowHide == 'hide') {
                    this.thumbShowHide = 'show'
                } else {
                    this.thumbShowHide = 'hide'
                }
            },
            handleFileUpload(){
                this.item.video_url = this.$refs.file.files[0];
            },
            handleThumbUpload(){
                this.item.thumbnail = this.$refs.file.files[0];
            },
            submitClose(){
                this.submit_close = true;
                var form = this.$refs.item_form
                form.click()
            },
            submitForm(){
                var formData
                if(!this.tagsFocus){
                    this.submitProcessing = true;
                    
                    var postURL = this.item_url;
    
                    if(this.$route.name == 'FlickEdit'){
                        postURL = this.item_url + '/' + this.$route.params.public_id
                        this.item._method = 'PATCH';
                    }
                    formData = serialize(this.item)
    
                    API.post(postURL, formData, { 'Content-Type': 'multipart/form-data' } ).then((response) => {
                        this.toast.info(response.message);
                        if(this.submit_close){
                            this.$router.push({ name: 'FlickList' });
                        }
                        this.submitProcessing = false;
                    }).catch((error) => {
                        this.submitProcessing = false;
                        console.log(error)
                    });
                }
            },
            addTag (event) {
                event.preventDefault()
                var val = event.target.value.trim()
                if (val.length > 0) {
                    this.item.keywords.push(val)
                    event.target.value = ''
                }
            },
            removeTag (index) {
                this.item.keywords.splice(index, 1)
            }
        }
    }
</script>