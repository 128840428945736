<template>
    <div class="page-container">
        <div class="card" >
            <div class="card-header">{{ page_title }}</div>
            <div class="card-body">
                <Form @submit="submitForm" :validation-schema="validationSchema" v-slot="{ errors }">
                    <div class="form-group row" name="Name">
                        <label class="col-12 col-md-2">Name</label>
                        <div class="col-sm-10">
                            <Field type="text" name="Name" :class="{'form-control':true,'is-invalid': errors.Name}" 
                                placeholder="Name" v-model="item.name" />
                            <ErrorMessage name="Name" class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="URL">
                        <label class="col-12 col-md-2">URL</label>
                        <div class="col-sm-10">
                            <Field type="text" name="URL" :class="{'form-control':true,'is-invalid': errors.URL}" 
                                placeholder="URL" v-model="item.sponsor_url" />
                            <ErrorMessage name="URL" class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-12 col-md-2">Image</label>
                        <div class="col-sm-10">
                            <input type="file" ref="file" id="file" @change="uploadFile">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-12 col-md-2"></label>
                        <div class="col-sm-10">
                            <img :src="image" width="250" v-if="image" alt="">
                        </div>
                    </div>
                    <div class="pt-3 text-right">
                        <button class="btn btn-success" :disabled="submitProcessing" ref="item_form">
                            <span v-if="!submitProcessing">Update</span>
                            <span v-else>Please Wait...</span>
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import API from "../../../api/http";
    import { Form, Field, ErrorMessage  } from 'vee-validate';
    import * as yup from 'yup';
    import { useToast } from "vue-toastification";
    import { serialize } from 'object-to-formdata'

    export default {
        name: 'Sponsor',
        components: {
            Form, Field, ErrorMessage,
        },
        data(){
            const schema = yup.object().shape({
                Name: yup.string().required().min(2),
                URL: yup.string().required().min(2),
            });

            return {
                page_title: 'Sponsor',
                item_url: 'sponsor/1',
                item: {
                    name: null,
                    sponsor_url: null,
                    sponsor_image: null
                },
                image: null,
                submitProcessing: false,
                submit_close: false,
                validationSchema: schema,
                toast: useToast()
            }
        },
        created(){
            API.get(this.item_url)
            .then((response) => {
                if(response.sponsor != null){
                    this.item = response.sponsor
                    this.image = response.sponsor.sponsor_image
                }
            });
        },
        methods: {
            uploadFile(){
                this.item.sponsor_image = this.$refs.file.files[0];
            },
            submitClose(){
                this.submit_close = true;
                var form = this.$refs.item_form
                form.click()
            },
            submitForm(){
                var formData
                this.submitProcessing = true;
                this.item._method = 'PATCH';
                formData = serialize(this.item)
                API.post(this.item_url, formData, { 'Content-Type': 'multipart/form-data' } ).then((response) => {
                    this.toast.info(response.message);
                    if(this.submit_close){
                        this.$router.push({ name: 'Leaderboard' });
                    }
                    this.item = response.sponsor
                    this.image = response.sponsor.sponsor_image
                    this.submitProcessing = false;
                }).catch((error) => {
                    this.submitProcessing = false;
                    console.log(error)
                });
            }
        }
    }
</script>