<template>
    <div class="page-container">
        <div class="card">
            <div class="row card-header m-0 p-0 text-center">
                <div @click="tab = 'survey'" :class="(tab == 'survey') ? 'active-tab pointer p-2 col-2' : 'pointer p-2 col-2'">{{ page_title }}</div>
                <div @click="tab = 'users'" v-if="item.survey_sbmited_users && item.survey_sbmited_users.length > 0" :class="(tab == 'users') ? 'active-tab border-left pointer p-2 col-2' : 'border-left pointer p-2 col-2'">Users</div>
            </div>
            <div class="card-body">
                <Form @submit="submitForm" v-if="tab == 'survey'">

                    <div v-if="item.survey.length > 0">
                        <div v-for="(inQuestion, index) in item.survey" :key="index" class="mb-3">
                            <p class="collapse-qna-div mb-0">
                                <button class="btn btn-primary text-left w-100 h-100" @click="openDiv(index)" type="button" data-toggle="collapse" :data-target="'#questionDiv_'+(inQuestion.public_id) ? inQuestion.public_id : index" aria-expanded="false" :aria-controls="'questionDiv_'+(inQuestion.public_id) ? inQuestion.public_id : index">
                                    {{ inQuestion.question }}
                                </button>
                            </p>
                            <div :class="'collapse ' + (index == divIndex ? 'show' : 'hide') " :id="'questionDiv_'+(inQuestion.public_id) ? inQuestion.public_id : index ">
                                <div class="card card-body border-bottom border-left-0 border-top-0 border-right-0">
                                    <input type="text" class="form-control" placeholder="Enter Question" v-model="inQuestion.question">
                                    <div class="mt-2">
                                        <div v-if="inQuestion.images && inQuestion.images.length > 0" class="row mb-2">
                                            <div v-for="(image, j) in inQuestion.images" :key="j" class="col-4 mt-2">
                                                <div class="d-none">
                                                    <input type="file" class="form-control" :id="'file_'+index+'_'+j" ref="file" v-on:change="handleFileUpload(index, j)" placeholder="Add Image">
                                                </div>
                                                <div class="h-100">
                                                    <div class="img-upload-div bg-dark h-100">
                                                        <button type="button" class="btn btn-danger position-absolute rounded-0" style="right: 15px; z-index: 1;" @click="removeImage(index, j)">Remove</button>
                                                        <img class="img-upload-click w-100 h-100" @click="clickUploadImg('file_'+index+'_'+j)" :src="previewImage[index] && previewImage[index][j] ? previewImage[index][j] : image.image">
                                                        <p class="img-upload-text">Click to upload</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <button class="btn btn-primary h-25 mt-2" type="button" v-if="inQuestion.images.length < 3" @click="addImage(index)">+ Add Image</button>
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <h4 v-if="inQuestion.answers.length > 0">Options</h4>
                                        <div v-if="inQuestion.answers.length > 0" class="row">
                                            <div v-for="(inAnswer, i) in inQuestion.answers" :key="i" class="col-12">
                                                <div class="input-group mb-2">
                                                    <input type="text" class="form-control" v-model="inAnswer.survey_answer" placeholder="Enter Option">
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-danger" v-if="inQuestion.answers.length > 1" @click="removeOption(index, i)">Remove Option</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="ml-auto btn btn-primary mb-2" type="button" v-if="inQuestion.answers.length < 5" @click="addOption(index)">+ Add Option</button>
                                    <button type="button" class="btn btn-danger ml-auto" v-if="item.survey.length > 1" @click="removeQuestion(index)">Remove Question</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button type="button" class="mb-2 btn btn-secondary" style="margin-left: 84%;" @click="addQuestion()">+ Add Question</button>

                    <div class="pt-3 border-top text-right">
                        <button class="btn btn-success" :disabled="submitProcessing" ref="item_form">
                            <span v-if="!submitProcessing">Save</span>
                            <span v-else>Please Wait...</span>
                        </button>
                        <button class="btn btn-success ml-2" type="button" @click="submitClose" :disabled="submitProcessing">
                            <span v-if="!submitProcessing">Save & Close</span>
                            <span v-else>Please Wait...</span>
                        </button>
                        <router-link class="btn btn-warning ml-2" :to="{name: 'SurveyList'}" :disabled="submitProcessing">
                            <span v-if="!submitProcessing">Cancel</span>
                            <span v-else>Please Wait...</span>
                        </router-link>
                    </div>
                </Form>
                <div v-if="tab == 'users'">
                    <table class="table table-data2">
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>First name</th>
                                <th>Last name</th>
                                <th>Generation</th>
                                <th class="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(user) in item.survey_sbmited_users" :key="user.public_id" class="tr-shadow">
                                <td>{{ user.email }}</td>
                                <td>{{ user.first_name }}</td>
                                <td>{{ user.last_name }}</td>
                                <td>{{ user.generation }}</td>
                                <td class="text-right">
                                    <button type="button" @click="getUserAnswers(user)" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalRight">
                                        View 
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade drawer right-align" id="exampleModalRight" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{UserName}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <template v-if="!UserData">
                            <p>Please Wait...</p>
                        </template>
                        <template v-else>
                            <div v-if="UserAnswers && UserAnswers.length > 0">
                                <div v-for="(userQuestion, index) in UserAnswers" :key="userQuestion.public_id">
                                    <p class="text-secondary">{{(index+1) + ') ' +userQuestion.question[0].question}}</p>
                                    <template v-for="(userAnswer) in userQuestion.question[0].answers" :key="userAnswer.public_id" >
                                        <span class="pl-3 ml-1" v-if="userAnswer.public_id == userQuestion.survey_answer_public_id">
                                            {{ userAnswer.survey_answer }}
                                        </span>
                                    </template>
                                    <hr>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import API from "../../../api/http";
    import { Form } from 'vee-validate';
    import { useToast } from "vue-toastification";
    import { serialize } from 'object-to-formdata'

    export default {
        name: 'Survey',
        components: {
            Form
        },
        computed:{
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        data(){

            return {
                publicURL : process.env.VUE_APP_BASE_URL,
                page_title: 'Survey',
                item_url: 'survey',
                submitProcessing: false,
                submit_close: false,
                item: {
                    survey: []
                },
                tab: 'survey',
                UserAnswers: [],
                UserName: '',
                divIndex: -1,
                uploadImgText: false,
                UserData: false,
                previewImage: [],
                toast: useToast()
            }
        },
        created(){
            if (this.$route.params.public_id) {
                API.get(this.item_url + '/' + this.$route.params.public_id)
                .then((response) => {
                    if (response.surveys) {
                        this.item = response.surveys;
                    } else {
                        this.item.survey.push({
                            question: null,
                            answers: [{
                                survey_answer: null
                            }],
                            images: []
                        })
                    }
                });
            } else {
                this.item.survey.push({
                    question: null,
                    answers: [{
                        survey_answer: null
                    }],
                    images: []
                })
            }
        },
        methods: {
            getUserAnswers (user) {
                this.UserData = false;
                this.UserName = user.first_name + " " + user.last_name
                API.get('user-answer?user_public_id=' + user.public_id + '&survey_public_id=' + this.$route.params.public_id)
                .then((response) => {
                    this.UserAnswers = response.user_answers;
                    this.UserData = true;
                });
            },
            openDiv(index){
                if (this.divIndex == index) {
                    this.divIndex = -1
                } else {
                    this.divIndex = index
                }
            },
            handleFileUpload(index, j){
                const files = event.target.files
                const fileReader = new FileReader()
                fileReader.addEventListener('load', () => {
                    if(!this.previewImage[index]) {

                        this.previewImage[index]= []
                    }
                    this.previewImage[index][j] = fileReader.result
                })
                fileReader.readAsDataURL(files[0])
                this.item.survey[index].images[j].image = files[0]
            },
            clickUploadImg (id) {
                document.getElementById(id).click()
            },
            addQuestion () {
                this.item.survey.push({
                    question: null,
                    answers: [{
                        survey_answer: null
                    }],
                    images: []
                })
                this.divIndex = (this.item.survey.length - 1);
            },
            addOption (index) {
                this.item.survey[index].answers.push({
                    survey_answer: null
                })
            },
            addImage (index) {
                this.item.survey[index].images.push({
                    image: this.publicURL + 'images/image.png'
                })
            },
            removeQuestion (index) {
                if (this.item.survey[index].public_id) {
                    if (confirm("DO you want to delete question?")) {
                        API.post('delete-survey-question/' + this.item.survey[index].public_id ,{
                            _method: 'DELETE',
                        }).then((response) => {
                            this.item.survey.splice(index, 1)
                            this.toast.info(response.message);
                        })
                    }
                } else {
                    this.item.survey.splice(index, 1)
                }
            },
            removeOption (index, i) {
                if (this.item.survey[index].answers[i].public_id) {
                    if (confirm("DO you want to delete Option?")) {
                        API.post('delete-survey-answer/' + this.item.survey[index].answers[i].public_id ,{
                            _method: 'DELETE',
                        }).then((response) => {
                            this.item.survey[index].answers.splice(i, 1)
                            this.toast.info(response.message);
                        })
                    }
                } else {
                    this.item.survey[index].answers.splice(i, 1)
                }
            },
            removeImage (index, i) {
                if (this.item.survey[index].images[i].public_id) {
                    if (confirm("DO you want to delete Image?")) {
                        API.post('delete-survey-image/' + this.item.survey[index].images[i].public_id ,{
                            _method: 'DELETE',
                        }).then((response) => {
                            this.item.survey[index].images.splice(i, 1)
                            if(this.previewImage[index]) {

                                this.previewImage[index][i] = null
                            }
                            this.toast.info(response.message);
                        })
                    }
                } else {
                    this.item.survey[index].images.splice(i, 1)
                    if(this.previewImage[index]) {

                        this.previewImage[index][i] = null
                    }
                }
            },
            submitClose(){
                this.submit_close = true;
                var form = this.$refs.item_form
                form.click()
            },
            submitForm(){
                var formData
                const options = {
                    indices: true
                }
                this.submitProcessing = true;

                var postURL = this.item_url;
                if(this.$route.name == 'SurveyEdit'){
                    postURL = this.item_url + '/' + this.$route.params.public_id
                    this.item._method = 'PATCH';
                }

                formData = serialize(this.item, options)

                API.post(postURL, formData, { 'Content-Type': 'multipart/form-data' }  ).then((response) => {
                    this.toast.info(response.message);
                    if(this.submit_close){
                        this.$router.push({ name: 'SurveyList' });
                    }
                    this.submitProcessing = false;
                }).catch((error) => {
                    this.submitProcessing = false;
                    console.log(error)
                });
            },
        }
    }
</script>