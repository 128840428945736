<template>
    <div class="page-container">
        <!-- <div class="header-wrap row">
            <div class="col-12"><h2 class="ml-2">{{ page_title }}</h2></div>
        </div> -->
        <div class="card" v-if="item">
            <div class="card-header">Edit {{ page_title }}</div>
            <div class="card-body">
                <Form @submit="submitForm" :validation-schema="validationSchema" v-slot="{ errors }">

                    <div class="row border-bottom mb-3">
                        <div class="form-group row col-6" name="Member Name">
                            <div class="mx-2 w-100">
                                <label>Member Name</label>
                                <input type="text" class="form-control" :value="item.member_name" readonly />
                            </div>
                        </div>
                        <div class="form-group row col-6" name="Referral Code">
                            <div class="mx-2 w-100">
                                <label>Referral Code</label>
                                <input type="text" class="form-control" :value="item.referral_code " readonly />
                            </div>
                        </div>

                        <div class="form-group row col-6" name="Referral Code">
                            <div class="mx-2 w-100">
                                <label>Code Of Ethics</label>
                                <div>
                                    <input type="text" class="form-control" :value="item.code_of_ethics == 0 ? 'Not' : 'Accepted'" readonly />
                                </div>
                            </div>
                        </div>

                        <div class="form-group row col-6" name="Referral Code">
                            <div class="mx-2 w-100">
                                <label>Leaderbord Points</label>
                                <div>
                                    <input type="text" name="referral_code " class="form-control" :value="item.leaderbord_pioint" readonly />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="form-group row" name="First Name">
                        <label class="col-12 col-md-2">First Name</label>
                        <div class="col-sm-10">
                            <Field type="text" name="first_name" 
                                :class="{'form-control':true,'is-invalid': errors.first_name}" 
                                placeholder="First Name" v-model="item.first_name" />
                            <ErrorMessage name="first_name" class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="Last Name">
                         <label class="col-12 col-md-2">Last Name</label>
                         <div class="col-sm-10">
                            <Field type="text" name="last_name" :class="{'form-control':true,'is-invalid': errors.last_name}" 
                                placeholder="Last Name" v-model="item.last_name" />
                            <ErrorMessage name="last_name" class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="Email Address">
                        <label class="col-12 col-md-2">Email</label>
                        <div class="col-sm-10">
                            <Field type="text" readonly name="email" id="email"
                                :class="{'form-control':true,'is-invalid': errors.email}" 
                                placeholder="Email Address" v-model="item.email" />
                            <ErrorMessage name="email" class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="Password">
                        <label class="col-12 col-md-2">Password</label>
                        <div class="col-sm-10">
                            <Field type="password" name="password" :class="{'form-control':true,'is-invalid': errors.password}" 
                                v-model="item.password" />
                            <ErrorMessage name="password" class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="Generation">
                        <label class="col-12 col-md-2">Generation</label>
                        <div class="col-sm-10">
                            <Field type="text" name="generation" :class="{'form-control':true,'is-invalid': errors.generation}" 
                                v-model="item.generation" />
                            <ErrorMessage name="generation"  class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="About">
                        <label class="col-12 col-md-2">About</label>
                        <div class="col-sm-10">
                            <Field as="textarea" name="about" :class="{'form-control':true,'is-invalid': errors.generation}" 
                                v-model="item.about" />
                            <ErrorMessage name="about"  class="invalid-feedback" />
                        </div>
                    </div>
                    
                    <div class="form-group row" name="Twitter">
                        <label class="col-12 col-md-2">Twitter</label>
                        <div class="col-sm-10">
                            <Field type="url" name="twitter" :class="{'form-control':true,'is-invalid': errors.twitter}" 
                                v-model="item.twitter" />
                            <ErrorMessage name="twitter"  class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="Instagram">
                        <label class="col-12 col-md-2">Instagram</label>
                        <div class="col-sm-10">
                            <Field type="url" name="instagram" :class="{'form-control':true,'is-invalid': errors.instagram}" 
                                v-model="item.instagram" />
                            <ErrorMessage name="instagram"  class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="Tiktok">
                        <label class="col-12 col-md-2">Tiktok</label>
                        <div class="col-sm-10">
                            <Field type="url" name="tiktok" :class="{'form-control':true,'is-invalid': errors.tiktok}" 
                                v-model="item.tiktok" />
                            <ErrorMessage name="tiktok"  class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="Website">
                        <label class="col-12 col-md-2">Website</label>
                        <div class="col-sm-10">
                            <Field type="url" name="website" :class="{'form-control':true,'is-invalid': errors.website}" 
                                v-model="item.website" />
                            <ErrorMessage name="website" class="invalid-feedback" />
                        </div>
                    </div>
                    
                    <div class="pt-3 border-top text-right">
                        <button class="btn btn-success" :disabled="submitProcessing" ref="item_form">
                            <span v-if="!submitProcessing">Save</span>        
                            <span v-else>Please Wait...</span>
                        </button>
                        <button class="btn btn-success ml-2" type="button" @click="submitClose" :disabled="submitProcessing">
                            <span v-if="!submitProcessing">Save & Close</span>        
                            <span v-else>Please Wait...</span>
                        </button>
                        <router-link class="btn btn-warning ml-2" :to="{name: 'UserList'}" :disabled="submitProcessing">
                            <span v-if="!submitProcessing">Cancel</span>        
                            <span v-else>Please Wait...</span>
                        </router-link>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import API from "../../../api/http";
    import { Form, Field, ErrorMessage  } from 'vee-validate';
    import * as yup from 'yup';
    import { useToast } from "vue-toastification";

    export default {
        name: 'User',
        components: {
            Form, Field, ErrorMessage,
        },
        computed:{
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        data(){
            const schema = yup.object().shape({
                first_name: yup.string().required().min(2),
                last_name: yup.string().required(),
                email: yup.string().required().email(),
                password: yup.string().min(6),
                generation: yup.string().required(),
                website: yup.string().url(),
            });

            return {
                page_title: 'User',
                item_url: 'user',
                submitProcessing: false,
                submit_close: false,
                item: null,
                validationSchema: schema,
                toast: useToast()
            }
        },
        created(){
            API.get(this.item_url + '/' + this.$route.params.public_id)
            .then((response) => {
                this.item = response.user;
            });
        },
        methods: {
            submitClose(){
                this.submit_close = true;
                var form = this.$refs.item_form
                form.click()
            },
            submitForm(){
                this.submitProcessing = true;
                
                var postURL = this.item_url;

                if(this.$route.name == 'UserEdit'){
                    postURL = this.item_url + '/' + this.$route.params.public_id
                    this.item._method = 'PATCH';
                }

                API.post(postURL, this.item ).then((response) => {
                    this.toast.info(response.message);
                    if(this.submit_close){
                        this.$router.push({ name: 'UserList' });
                    }
                    this.submitProcessing = false;
                }).catch((error) => {
                    this.submitProcessing = false;
                    console.log(error)
                });
            },
        }
    }
</script>