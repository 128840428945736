<template>
  <div class="page-container">
    <div class="header-wrap row">
      <div class="col-4">
        <h2 class="ml-2">{{ page_title }}</h2>
      </div>
      <div class="col-6 text-right">
        <button tag="button" class="btn btn-success btn-l" v-on:click="resetContest()">
          Reset Contest
        </button>
      </div>
      <div class="col-2 text-right">
        <router-link tag="button" class="btn btn-success btn-l" :to="{ name: 'Sponsor' }">
          Sponsor
        </router-link>
      </div>
    </div>

    <div class="table-responsive table-responsive-data2">
      <table class="table table-data2">
        <thead>
          <tr>
            <th>Picture</th>
            <th>Name</th>
            <th>Member name</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="!data_loaded">
            <tr>
              <td colspan="5">Please Wait...</td>
            </tr>
          </template>
          <template v-if="!items && data_loaded">
            <tr>
              <td colspan="5">No {{ page_title }} Available</td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(item) in items" :key="item.publi_id" class="tr-shadow">
              <td>
                <img class="rounded-circle"
                  :src="item.profile_image != '' ? item.profile_image : publicURL + 'images/default_profile.jfif'"
                  width="80" height="80">
              </td>
              <td>{{ item.first_name }} {{ item.last_name }}</td>
              <td>{{ item.member_name }}</td>
              <td>{{ item.leaderbord_pioint }}</td>
            </tr>
            <tr v-if="pagination.total_pages > 1">
              <td colspan="5">
                <nav>
                  <ul class="pagination">
                    <li :class="{ 'page-item': true, 'active': page === pagination.current_page }"
                      v-for="page in pagination.total_pages" :key="page">
                      <a href="JavaScript:void(0);" class="page-link" @click="init(page)"> {{ page }} </a>
                    </li>
                  </ul>
                </nav>
              </td>
            </tr>
          </template>

        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import API from "../../../api/http";
import { useToast } from "vue-toastification";

export default {
  name: 'Leaderboard',
  data() {
    return {
      data_loaded: false,
      show_points: false,

      pagination: {
        current_page: 1
      },
      items: {},

      item_url: 'leaderbord',
      page_title: 'Leaderboard',
      reset_contest_url: 'reset-contest',
      toast: useToast(),
      publicURL: process.env.VUE_APP_BASE_URL,
    }
  },
  created() {
    this.init(this.pagination.current_page);
  },
  methods: {
    init(current_page) {
      var url = this.item_url + '?page=' + current_page;
      API.get(url).then((response) => {
        this.data_loaded = true;
        this.items = response.leaderbord.data;
        this.pagination = response.leaderbord.pagination;
      })
    },

    resetContest() {
      if (confirm('Are you sure?  contest reset')) {
        API.post(this.reset_contest_url).then((response) => {
          this.toast.success(response.message);

        });
      }
    }
  }
}
</script>