<template>
  <div class="page-container">
    <div class="card" v-if="item">
      <div class="card-header">Edit {{ page_title }}</div>
      <div class="card-body">
        <Form @submit="submitForm">

          <div class="row border-bottom mb-3 pb-4">

            <div class="form-group row col-12">
              <div class="mx-2 w-100">
                <label>Name:</label>
                <div>
                  <input class="form-control" v-model="item.name">
                </div>
              </div>
            </div>

          </div>

          <div class="pt-3 text-right">
            <button class="btn btn-success" :disabled="submitProcessing" ref="item_form">
              <span v-if="!submitProcessing">Save</span>
              <span v-else>Please Wait...</span>
            </button>
            <button class="btn btn-success ml-2" type="button" @click="submitClose" :disabled="submitProcessing">
              <span v-if="!submitProcessing">Save & Close</span>
              <span v-else>Please Wait...</span>
            </button>
            <router-link class="btn btn-warning ml-2" :to="{ name: 'UserChatGroup' }" :disabled="submitProcessing">
              <span v-if="!submitProcessing">Cancel</span>
              <span v-else>Please Wait...</span>
            </router-link>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../api/http";
import { useToast } from "vue-toastification";
import { Form } from 'vee-validate';
import { serialize } from 'object-to-formdata'

export default {
  name: 'UserChatGroupForm',
  components: {
    Form
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    }
  },
  data() {
    return {
      page_title: 'DAO Group',
      item_url: 'user-chat-group',
      submitProcessing: false,
      submit_close: false,
      item: {
        name: null,
      },
      toast: useToast(),
    }
  },
  created() {
    if (this.$route.params.user_chat_group_id) {
      API.get(this.item_url + '/' + this.$route.params.user_chat_group_id).then((response) => {
        this.toast.info(response.message);
        this.item.name = response.chat_group.name;
      });
    }
  },
  methods: {
    submitClose() {
      this.submit_close = true;
      var form = this.$refs.item_form
      form.click()
    },
    submitForm() {
      var formData

      this.submitProcessing = true;

      var postURL = this.item_url;

      if (this.$route.name == 'UserChatGroupEdit') {
        postURL = this.item_url + '/' + this.$route.params.user_chat_group_id
        this.item._method = 'PATCH';
      }
      formData = serialize(this.item)

      API.post(postURL, formData, {}).then((response) => {
        this.toast.info(response.message);
        if (this.submit_close) {
          console.log(123);
          this.$router.push({ name: 'UserChatGroup' });
        }
        this.submitProcessing = false;
      }).catch((error) => {
        this.submitProcessing = false;
        console.log(error)
      });

    },
    addTag(event) {
      event.preventDefault()
      var val = event.target.value.trim()
      if (val.length > 0) {
        this.item.keywords.push(val)
        event.target.value = ''
      }
    },
    removeTag(index) {
      this.item.keywords.splice(index, 1)
    }
  }
}
</script>