<template>
  <div class="page-container">
    <div class="header-wrap row">
      <div class="col-4">
        <h2 class="ml-2">{{ page_title }}</h2>
      </div>
      <div class="col-8 text-right">
        <div class="row w-100">
          <div class="input-group col-6">
            <button class="btn btn-info btn-sm btn-dark">Squad</button>
            <button :class="{ 'btn btn-info btn-sm ml-2': true, 'btn-dark': filter.flaged }"
              @click="goToRoute('flaged')">Flagged</button>
            <button :class="{ 'btn btn-info btn-sm ml-2': true, 'btn-dark': filter.hastag }"
              @click="goToRoute('hastag')">Trending</button>
          </div>
          <form @submit.prevent="init(1, true)" class=" col-6 float-right">
            <div class="input-group">
              <input type="text" class="form-control" v-model="search" placeholder="Search">
              <div class="input-group-append">
                <button class="btn btn-success btn-l" type="button" @click="init(1, true)">Search</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="table-responsive table-responsive-data2">
      <table class="table table-data2">
        <thead>
          <tr>
            <th>Type</th>
            <th>User</th>
            <th>Blast</th>
            <th v-if="show_points">Points</th>
            <th class="create-at">Created at</th>
            <th class="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="!data_loaded">
            <tr>
              <td colspan="5">Please Wait...</td>
            </tr>
          </template>
          <template v-if="items.length == 0 && data_loaded">
            <tr>
              <td colspan="5">No squad blasts available</td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(item, index) in items" :key="item.publi_id" class="tr-shadow">
              <td class="text-capitalize">{{ item.type }}</td>
              <td>
                <span class="block-email">{{ item.creator.member_name }}</span>
              </td>
              <td>{{ item.text }}</td>
              <td v-if="show_points">{{ item.points }}</td>
              <td>{{ item.created }}</td>
              <td class="text-right">
                <div class="btn-group">
                  <button class="btn btn-warning" type="button" v-if="item.flag"
                    @click="removeFlag(item.public_id, index)">
                    <!-- <i class="far fa-check-circle"></i> -->
                    <!-- <fa :icon="['far', 'fa-check-circle']" /> -->
                    Remove flag
                  </button>
                  <router-link :to="{ name: 'BlastEdit', params: { public_id: item.public_id } }" class="btn btn-success"
                    type="button">
                    Edit
                  </router-link>
                  <button class="btn btn-danger" type="button" @click="deleteItem(item.public_id, index)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
            <tr v-if="pagination.total_pages > 1">
              <td colspan="5">
                <nav>
                  <ul class="pagination">
                    <li :class="{ 'page-item': true, 'active': page === pagination.current_page }"
                      v-for="page in pagination.total_pages" :key="page">
                      <a href="JavaScript:void(0);" class="page-link" @click="init(page)"> {{ page }} </a>
                    </li>
                  </ul>
                </nav>
              </td>
            </tr>
          </template>

        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import API from "../../../api/http";

export default {
  name: 'List',
  data() {
    return {
      page_title: 'Blasts',
      item_url: 'blast',
      data_loaded: false,
      show_points: false,
      search: null,
      pagination: {
        current_page: 1
      },
      items: {},
      filter: {
        flaged: false,
        squad: false,
        hastag: false
      }
    }
  },
  created() {
    this.init(this.pagination.current_page);
  },
  methods: {
    init(current_page) {

      var url = this.item_url + '?page=' + current_page;
      url += '&type=squad'
      if (this.search) {
        url += '&search=' + this.search
      }

      API.get(url).then((response) => {
        this.data_loaded = true;
        this.items = response.blast.data;
        this.pagination = response.blast.pagination;
      })
    },
    deleteItem(item_id, index) {
      if (confirm('Are you sure?')) {
        API.post(this.item_url + '/' + item_id, {
          _method: 'DELETE',
        }).then((response) => {
          this.items.splice(index, 1)
          this.toast.info(response.message);
        })
      }
    },
    goToRoute(type) {
      switch (type) {
        case "flaged":
          this.$router.push('/blasts/flagged');
          break;

        case "hastag":
          this.$router.push('/blasts/hastag');
          break;

        default:
          break;
      }

    },
    removeFlag(item_id, index) {
      if (confirm('Are you sure?')) {
        API.post('unflag-blast/' + item_id, {
          _method: 'DELETE',
        }).then((response) => {
          this.items.splice(index, 1)
          this.toast.info(response.message);
        })
      }
    }
  }
}
</script>