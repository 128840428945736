<template>
  <div class="page-container">
    <div class="header-wrap row">
      <div class="col-4">
        <h2 class="ml-2">{{ page_title }}</h2>
      </div>
      <div class="col-8 text-right">
        <div class="row w-100">
        </div>
      </div>
    </div>

    <div class="table-responsive table-responsive-data2">
      <table class="table table-data2">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th class="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="!data_loaded">
            <tr>
              <td colspan="5">Please Wait...</td>
            </tr>
          </template>
          <template v-if="!items && data_loaded">
            <tr>
              <td colspan="5">No {{ page_title }} Available</td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(item, index) in items" :key="item.publi_id" class="tr-shadow">

              <td>{{ index + 1 }}</td>
              <td>{{ item.name }}</td>
              <td class="text-right">
                <div class="btn-group">
                  <!-- <a href="#" class="btn btn-success">Edit</a> -->
                  <router-link :to="{ name: 'UserChatGroupEdit', params: { user_chat_group_id: item.id } }"
                    class="btn btn-success" type="button">
                    Edit
                  </router-link>
                </div>
              </td>
            </tr>
            <tr v-if="pagination.total_pages > 1">
              <td colspan="5">
                <nav>
                  <ul class="pagination">
                    <li :class="{ 'page-item': true, 'active': page === pagination.current_page }"
                      v-for="page in pagination.total_pages" :key="page">
                      <a href="JavaScript:void(0);" class="page-link" @click="init(page)"> {{ page }} </a>
                    </li>
                  </ul>
                </nav>
              </td>
            </tr>
          </template>

        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import API from "../../../api/http";
import { useToast } from "vue-toastification";

export default {
  name: 'List',
  data() {
    return {
      page_title: 'DAO Group',
      item_url: 'user-chat-group',
      data_loaded: false,
      show_points: false,
      search: null,
      pagination: {
        current_page: 1
      },
      items: {},
      total_member_count: 0,
      toast: useToast()
    }
  },
  created() {
    this.init(this.pagination.current_page);
  },
  methods: {
    init(current_page) {
      var url = this.item_url + '?page=' + current_page;
      if (this.search) {
        url += '&search=' + this.search
      }
      API.get(url).then((response) => {
        this.data_loaded = true;
        this.items = response.chat_group.data;
        this.pagination = response.chat_group.pagination;
      })
    },
  }
}
</script>